//#region SORTING OPTIONS
import { SortOption } from "./sorting";

export const orderSortingOptions: SortOption[] = [
  { id: 0, value: null, label: "none", current: false, direction: null },
  {
    id: 1,
    value: "promo_id",
    label: "promo code ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 2,
    value: "promo_id",
    label: "promo code descending",
    current: false,
    direction: "desc",
  },
  {
    id: 3,
    value: "order_status",
    label: "order status ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 4,
    value: "order_status",
    label: "order status descending",
    current: false,
    direction: "desc",
  },
  {
    id: 5,
    value: "updated_at",
    label: "last updated ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 6,
    value: "updated_at",
    label: "last updated descending",
    current: false,
    direction: "desc",
  },
  {
    id: 7,
    value: "shipping_date",
    label: "shipping date ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 8,
    value: "shipping_date",
    label: "shipping date descending",
    current: false,
    direction: "desc",
  },
];

export const storeSortingOptions: SortOption[] = [
  { id: 0, value: null, label: "none", current: false, direction: null },
  {
    id: 1,
    value: "external_store_id",
    label: "store number ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 2,
    value: "external_store_id",
    label: "store number descending",
    current: false,
    direction: "desc",
  },
  {
    id: 3,
    value: "country_name",
    label: "country name ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 4,
    value: "country_name",
    label: "country name descending",
    current: false,
    direction: "desc",
  },
  {
    id: 5,
    value: "city",
    label: "city name ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 6,
    value: "city",
    label: "city name descending",
    current: false,
    direction: "desc",
  },
  {
    id: 7,
    value: "updated_at",
    label: "last updated ascending",
    current: false,
    direction: "asc",
  },
  {
    id: 8,
    value: "updated_at",
    label: "last updated descending",
    current: false,
    direction: "desc",
  },
];
//#endregion

//#region MODALS
export const UPLOAD_DATA_OVERWRITE_TRANSMART =
  "UPLOAD_DATA_OVERWRITE_TRANSMART";
export const CREATE_OR_UPDATE_STOREFORMAT = "CREATE_OR_UPDATE_STOREFORMAT";
export const STORE_ADDRESS_MODAL_SAFETY = "STORE_ADDRESS_MODAL_SAFETY";
export const CREATE_OR_UPDATE_LANGUAGE = "CREATE_OR_UPDATE_LANGUAGE";
export const CHOOSE_PICKLIST_SUPPLIER = "CHOOSE_PICKLIST_SUPPLIER";
export const STORE_FORM_MODAL_SAFETY = "STORE_FORM_MODAL_SAFETY";
export const CREATE_OR_UPDATE_PROMO = "CREATE_OR_UPDATE_PROMO";
export const CREATE_OR_UPDATE_STORE = "CREATE_OR_UPDATE_STORE";
export const CREATE_OR_UPDATE_USER = "CREATE_OR_UPDATE_USER";
export const UPLOAD_DATA_FOR_STORE = "UPLOAD_DATA_FOR_STORE";
export const UPLOAD_DATA_FOR_PROMO = "UPLOAD_DATA_FOR_PROMO";
export const CHANGE_STORE_COMMENT = "CHANGE_STORE_COMMENT";
export const DELETE_PROJECT_SURE = "DELETE_PROJECT_SURE";
export const STORE_ADDRESS_MODAL = "STORE_ADDRESS_MODAL";
export const SHOW_SUBORDER_INFO = "SHOW_SUBORDER_INFO";
export const CANCEL_ORDER_SURE = "CANCEL_ORDER_SURE";
export const DELETE_STORE_SURE = "DELETE_STORE_SURE";
export const DELETE_USER_SURE = "DELETE_USER_SURE";
//#endregion
