//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { setStoresPageNumber } from "../../features/stores/storesSlice";
import { useTranslation } from "react-i18next";
import { MetaData } from "../../models/pagination";
import { Fragment } from "react";
import { Store } from "../../models/store";
import StoresTableControlePanel from "./StoresTableControlePanel";
import MainTableMotionWrapper from "../_layout/MainTableMotionWrapper";
import usePickerManager from "../../hooks/usePickerManager";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingOverlay from "../loading/LoadingOverlay";
import StoresTableRow from "./StoresTableRow";
import PaginationBar from "../paginationBar/PaginationBar";
import useHQmanager from "../../hooks/useHQmanager";
//#endregion

//#region INTERFACE
interface Props {
  commentHandler: (store: Store) => void;
  deleteHandler: (store: Store) => void;
  filtersLoaded: boolean;
  storesLoaded: boolean;
  metaData: MetaData | null;
  stores: Store[];
}
//#endregion

export default function StoresTable({
  commentHandler,
  deleteHandler,
  filtersLoaded,
  storesLoaded,
  metaData,
  stores,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();

  // Roles
  const { user } = useAppSelector((state) => state.account);
  const isPicker = usePickerManager(user);
  const isHQTeam = useHQmanager(user);
  const isAdmin = useAdminManager(user);

  //#endregion

  //#region FAILSAFE
  if (storesLoaded && stores.length <= 0)
    return <h2>{t("rituals.noStoresFoundWithThisFilterSelection")}</h2>;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Controle Panel */}
      <StoresTableControlePanel />

      {/* Table */}
      <div className="relative">
        {(!storesLoaded || !filtersLoaded) && <LoadingOverlay />}
        <MainTableMotionWrapper>
          <thead>
            <tr>
              {/* Store number */}
              <th
                scope="col"
                className="whitespace-nowrap pr-4 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                {t("rituals.storeId")}
              </th>

              {/* Store name */}
              <th
                scope="col"
                className="whitespace-nowrap pr-2 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                {t("rituals.storeName")}
              </th>

              {/* Street name */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                {t("rituals.streetName")}
              </th>

              {/* Postal code */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                {t("rituals.postalCode")}
              </th>

              {/* City */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                {t("rituals.city")}
              </th>

              {/* Country */}
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
              >
                {t("rituals.country")}
              </th>

              {/* Language */}
              <th
                scope="col"
                className="whitespace-nowrap w-auto px-2 py-3.5 text-left w-64 text-xs font-semibold text-gray-900"
              >
                {t("rituals.language")}
              </th>

              {/* Last updated */}
              <th
                scope="col"
                className="whitespace-nowrap w-auto px-2 py-3.5 text-left w-72 text-xs font-semibold text-gray-900"
              >
                {t("rituals.lastUpdated")}
              </th>

              {/* Openings data */}
              <th
                scope="col"
                className="whitespace-nowrap w-auto px-2 py-3.5 text-left w-72 text-xs font-semibold text-gray-900"
              >
                {t("rituals.openingsDate")}
              </th>

              {/* Last format */}
              <th
                scope="col"
                className="whitespace-nowrap w-auto px-2 py-3.5 text-left w-72 text-xs font-semibold text-gray-900"
              >
                {t("rituals.storeFormat")}
              </th>

              {/* Actions */}
              <th
                scope="col"
                className="whitespace-nowrap w-auto px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
              />
            </tr>
          </thead>
          <tbody className="bg-white">
            {stores.map((store) => (
              <StoresTableRow
                commentHandler={(store: Store) => commentHandler(store)}
                deleteHandler={(store: Store) => deleteHandler(store)}
                isHQTeam={isHQTeam}
                isPicker={isPicker}
                isAdmin={isAdmin}
                store={store}
                key={store.id}
              />
            ))}
          </tbody>
        </MainTableMotionWrapper>
        <footer>
          {metaData && metaData?.total > 0 && (
            <PaginationBar
              metaData={metaData}
              onPageChange={(page: number) =>
                dispatch(setStoresPageNumber({ page }))
              }
              pageType={t("rituals.stores")}
            />
          )}
        </footer>
      </div>
    </Fragment>
  );
  //#endregion
}
