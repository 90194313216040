//#region IMPORTS
import { useExpanded, useGroupBy, useSortBy, useTable } from "react-table";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { Item } from "../../models/item";
import SortUnsortIcon from "../icons/tableSortIcons.tsx/SortUnsortIcon";
import SortDescIcon from "../icons/tableSortIcons.tsx/SortDescIcon";
import SortAscIcon from "../icons/tableSortIcons.tsx/SortAscIcon";
//#endregion

//#region INTERFACE
interface Props {
  reference?: any;
  columns: any;
  status: boolean;
  group: string[];
  data: Item[];
}
//#endregion

export default function OrderdetailPageTable({
  reference,
  columns,
  status,
  group,
  data,
}: Props) {
  //#region REACT-TABLEV7 SETUP
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // @ts-ignore
    setGroupBy,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetExpanded: false,
    },
    useGroupBy,
    useSortBy,
    useExpanded
  );
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (status) {
      setGroupBy(group);
    }
  }, [group, status, setGroupBy]);
  //#endregion

  //#region RENDER
  return (
    <table
      {...getTableProps()}
      className="min-w-full divide-y divide-gray-300"
      ref={reference}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              >
                <div className="flex gap-2">
                  <span>{column.render("Header")}</span>
                  <span style={{ verticalAlign: "middle" }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortUnsortIcon color="#254559" />
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="bg-white">
        {rows.map((row: any, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="even:bg-gray-100">
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    {...cell.getCellProps()}
                    style={{
                      background:
                        (cell.isAggregated && "#254559") ||
                        (cell.isGrouped && "#254559"),
                      color:
                        (cell.isAggregated && "white") ||
                        (cell.isGrouped && "white"),
                    }}
                  >
                    {cell.isGrouped ? (
                      <div className="flex gap-2 items-center">
                        <button
                          className="rounded-full h-6 w-6 bg-amber-500 text-white shadow-sm hover:bg-amber-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-500 mr-4"
                          {...row.getToggleRowExpandedProps()}
                        >
                          {row.isExpanded ? (
                            <XCircleIcon className="size-6" />
                          ) : (
                            <PlusCircleIcon className="size-6" />
                          )}
                        </button>
                        {cell.render("Cell")}
                      </div>
                    ) : cell.isAggregated ? (
                      cell.render("Aggregated")
                    ) : cell.isPlaceholder ? null : (
                      cell.render("Cell")
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
  //#endregion
}
