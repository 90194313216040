//#region IMPORTS
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ArrowIconLeft from "../../icons/ArrowIconLeft";
//#endregion

//#region INTERFACE
interface Props {
  color?: string;
}
//#endregion

export default function GoBackButton({ color }: Props) {
  //#region SETUP
  const history = useHistory();
  //#endregion

  //#region LOGIC
  const clickHandler = () => {
    history.goBack();
  };
  //#endregion

  //#region RENDER
  return (
    <motion.button
      onClick={clickHandler}
      className="flex flex-col justify-center mr-4"
      whileHover={{
        scale: 1.2,
      }}
      whileTap={{ scale: 0.9 }}
    >
      <ArrowIconLeft color={color} width={24} />
    </motion.button>
  );
  //#endregion
}
