//#region IMPORTS
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { StoreFormat } from "../../models/storeFormat";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
//#endregion

//#region INTERFACE
interface Props {
  changeStoreFormatHandler: (status: string) => void;
  noStoreFormatError: boolean;
  currentStoreFormat: StoreFormat;
  storeFormats: string[];
}
//#endregion

export default function StoreFormatSelectBox({
  changeStoreFormatHandler,
  noStoreFormatError,
  currentStoreFormat,
  storeFormats,
}: Props) {
  //#region LOGIC
  const onChangeHandler = (value: any) => {
    changeStoreFormatHandler(value);
  };
  //#endregion
  //#region RENDER
  return (
    <Listbox
      value={!noStoreFormatError && currentStoreFormat.name}
      onChange={onChangeHandler}
    >
      {({ open }) => (
        <div className="relative">
          <Listbox.Button className="relative w-36 cursor-default rounded-md bg-white py-1.5 text-left text-gray-900 sm:text-xs sm:leading-6 hover:text-amber-500">
            <span
              className={classNames(
                noStoreFormatError ? "text-red-500 font-bold" : "",
                "block truncate"
              )}
            >
              {noStoreFormatError
                ? "No store format!"
                : currentStoreFormat.name}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-amber-500 bg-white"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
              {storeFormats.map((storeFormat, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    classNames(
                      active ? "bg-blue-50 text-white" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value={storeFormat}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-bold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {storeFormat}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-blue-600",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
  //#endregion
}
