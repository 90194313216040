//#region IMPORTS
import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";
import { Fragment } from "react";
//#endregion

//#region INTERFACE
interface Props {
  fieldName: string;
  labelText: string;
  errors: FieldErrors;
}
//#endregion

export default function StoreManageFormLabel({
  fieldName,
  labelText,
  errors,
}: Props) {
  //#region RENDER
  return (
    <Fragment>
      {!errors[fieldName] && (
        <label
          htmlFor={fieldName}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {labelText}
        </label>
      )}
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
            {message}
          </span>
        )}
      />
    </Fragment>
  );
  //#endregion
}
