//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { storeFormatSelectors } from "../../features/storeFormats/storeFormatSlice";
import { updateStoreAsync } from "../../features/stores/storesSlice";
import { Fragment } from "react";
import { Store } from "../../models/store";
import { toast } from "react-toastify";
import dateHelperWeirdFromBackend from "../../helpers/dateHelperWeirdFromBackend";
import StoresTableRowActions from "./StoresTableRowActions";
import StoreFormatSelectBox from "./StoreFormatSelectBox";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  commentHandler: (store: Store) => void;
  deleteHandler: (store: Store) => void;
  isHQTeam: boolean;
  isPicker: boolean;
  isAdmin: boolean;
  store: Store;
}
//#endregion

export default function StoresTableRow({
  commentHandler,
  deleteHandler,
  isHQTeam = false,
  isPicker = false,
  isAdmin = false,
  store,
}: Props) {
  const storeFormatsToSelect = useAppSelector(storeFormatSelectors.selectAll);
  const dispatch = useAppDispatch();

  //#region LOGIC
  // Fast update
  const fastUpdateHandler = async (
    storeId: number,
    newStoreFormatName: string | null,
    newOpeningsDate: string | null
  ) => {
    const { data } = await agent.StoreManagement.detail(storeId);
    if (newStoreFormatName) {
      const selectedStoreFormat = storeFormatsToSelect.find(
        (sf2s) => sf2s.name === newStoreFormatName
      );

      if (newStoreFormatName && selectedStoreFormat) {
        data.store_format_id = selectedStoreFormat ? selectedStoreFormat.id : 0;
        data.store_format_name = newStoreFormatName;
      } else {
        toast.error("No store format found with this name.");
        return;
      }
    }

    if (newOpeningsDate) {
      data.opening_date = newOpeningsDate;
    }

    if (data.same_as_address) {
      delete data.delivery_addresses;
    }
    await dispatch(updateStoreAsync({ id: storeId, values: data }));
    newStoreFormatName &&
      toast.success(
        `The store format of ${data.store_name} has been changed to ${newStoreFormatName}.`
      );
    newOpeningsDate &&
      toast.success(
        `The openingdate of ${data.store_name} has been changed to ${newOpeningsDate}.`
      );
  };
  //#endregion

  //#region RENDER
  return (
    storeFormatsToSelect && (
      <Fragment>
        <tr className="border-t border-slate-300 pt-12">
          {/* Store number */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-500 sm:pl-0">
            {store.external_store_id}
          </td>

          {/* Store name */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-500 sm:pl-0">
            {store.store_name}
          </td>

          {/* Street name */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-500">
            {store.address_1}
          </td>

          {/* Postal code */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {store.postal_code}
          </td>

          {/* City */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {store.city}
          </td>

          {/* Country */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {store.country_code}
          </td>

          {/* Language */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {store.language}
          </td>

          {/* Updated at */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {store.updated_at}
          </td>

          {/* Openings date */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {/* coming soon */}
            {isAdmin && store.opening_date ? (
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 hover:text-amber-500 sm:max-w-xs sm:text-sm sm:leading-6"
                type="date"
                value={store.opening_date}
                onChange={(e) =>
                  fastUpdateHandler(store.id, null, e.target.value)
                }
              />
            ) : (
              dateHelperWeirdFromBackend(store.opening_date)
            )}
          </td>

          {/* Storeformat */}
          <td className="whitespace-nowrap px-2 py-3.5 text-left text-xs text-gray-900">
            {isAdmin ? (
              <StoreFormatSelectBox
                noStoreFormatError={!store.store_format}
                currentStoreFormat={store.store_format}
                storeFormats={storeFormatsToSelect.map((s) => s.name)}
                changeStoreFormatHandler={(value: string) =>
                  fastUpdateHandler(store.id, value, null)
                }
              />
            ) : (
              <div className="inline-flex items-center rounded-full bg-gray-200 px-2 py-1 text-xs font-medium text-gray-900">
                {store.store_format ? store.store_format.name : "no format"}
              </div>
            )}
          </td>

          {/* Actions cell */}
          <StoresTableRowActions
            commentHandler={(store: Store) => commentHandler(store)}
            deleteHandler={(store: Store) => deleteHandler(store)}
            isHQTeam={isHQTeam}
            isPicker={isPicker}
            isAdmin={isAdmin}
            store={store}
          />
        </tr>
      </Fragment>
    )
  );
  //#endregion
}
