//#region IMPORTS
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
//#endregion

//#region INTERFACE
interface Props {
  deletehandler: () => void;
  detailhandler: () => void;
  edithandler: () => void;
  isPicker: boolean;
  isHQTeam: boolean;
  isAdmin: boolean;
}
//#endregion

export default function StoresTableRowActionsMenu({
  deletehandler,
  detailhandler,
  edithandler,
  isPicker,
  isHQTeam,
  isAdmin,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();
  //#endregion

  //#region RENDER
  return (
    <Menu as="div" className="relative flex justify-end">
      <Menu.Button
        className={classNames(
          !isPicker && !isAdmin && !isPicker
            ? "text-gray-200 hover:text-gray-200"
            : "text-gray-400 hover:text-gray-900",
          "-m-2.5 block p-2.5 "
        )}
      >
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {isAdmin || isPicker || isHQTeam ? (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={detailhandler}
                  className={classNames(
                    active ? "bg-gray-50" : "",
                    "block w-full px-3 py-1 text-sm leading-6 text-slate-600"
                  )}
                >
                  {t("rituals.detail")}
                </button>
              )}
            </Menu.Item>
          ) : null}

          {isAdmin ? (
            <Fragment>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={edithandler}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block w-full px-3 py-1 text-sm leading-6 text-slate-600"
                    )}
                  >
                    {t("rituals.edit")}
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={deletehandler}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block w-full px-3 py-1 text-sm leading-6 text-slate-600"
                    )}
                  >
                    {t("rituals.delete")}
                  </button>
                )}
              </Menu.Item>
            </Fragment>
          ) : null}
        </Menu.Items>
      </Transition>
    </Menu>
  );
  //#endregion
}
