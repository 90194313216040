//#region IMPORTS
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import { ReactChild } from "react";
import FilterButton from "../../components/genericButtons/filterButton/FilterButton";
import SearchButton from "../genericButtons/searchButton/SearchButton";
import ActionButton from "../genericButtons/actionButton/ActionButton";
import "./PageHeader.css";
//#endregion

//#region INTERFACE
interface Props {
  hideuploadButtonPlusIcon2?: boolean;
  removeAllbuttonsWhenCRUD?: boolean;
  hideuploadButtonPlusIcon?: boolean;
  downloadClickHandler?: () => void;
  uploadClickHandler2?: () => void;
  downloadButtonValue?: string;
  uploadButtonValue2?: string;
  createClickHandler?: () => void;
  uploadClickHandler?: () => void;
  uploadButtonValue?: string;
  createButtonValue?: string;
  filterCount?: number;
  children?: ReactChild | null;
  extraButtons?: ReactChild | null;
  download?: boolean;
  upload2?: boolean;
  isAdmin?: boolean;
  upload?: boolean;
  search?: boolean;
  create?: boolean;
  filter?: boolean;
  extra?: any;
  title: string;
  reset?: () => void;
}
//#endregion

export default function PageHeader({
  hideuploadButtonPlusIcon2 = false,
  hideuploadButtonPlusIcon = false,
  removeAllbuttonsWhenCRUD = false,
  downloadButtonValue = "download",
  downloadClickHandler,
  uploadClickHandler2,
  uploadButtonValue2,
  createClickHandler,
  uploadClickHandler,
  uploadButtonValue,
  createButtonValue,
  extraButtons = null,
  filterCount,
  children,
  download = false,
  upload2 = true,
  isAdmin,
  upload,
  search = false,
  create,
  filter = false,
  extra,
  title,
  reset,
}: Props) {
  //#region RENDER
  return (
    <header className="page__content__header">
      <div className="page__content__header__top">
        <div className="page__content__header__top__goback-container">
          {children}
          <h2
            onClick={reset}
            className={
              "page__content__header__top__title" + (reset ? " clickable" : "")
            }
          >
            {title.charAt(0).toUpperCase() + title.slice(1)}
          </h2>
        </div>
        {extra}

        {isAdmin && (
          <div className="page__content__header__top__create">
            {/* Extra buttons */}
            {extraButtons}

            {/* Download button */}
            {download && !removeAllbuttonsWhenCRUD && (
              <ActionButton
                clickHandler={downloadClickHandler}
                value={downloadButtonValue}
              >
                <ArrowDownCircleIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
              </ActionButton>
            )}
            {!upload && !removeAllbuttonsWhenCRUD && uploadButtonValue && (
              <ActionButton
                value={uploadButtonValue}
                clickHandler={uploadClickHandler}
                hidePlusIcon={hideuploadButtonPlusIcon}
              />
            )}
            {!upload2 && !removeAllbuttonsWhenCRUD && uploadButtonValue2 && (
              <ActionButton
                value={uploadButtonValue2}
                clickHandler={uploadClickHandler2}
                hidePlusIcon={hideuploadButtonPlusIcon2}
              />
            )}
            {/* Create button */}
            {!create && !removeAllbuttonsWhenCRUD && createButtonValue && (
              <ActionButton
                value={createButtonValue}
                clickHandler={createClickHandler}
              />
            )}
          </div>
        )}
      </div>
      {/* Filter button */}
      {filter && (
        <FilterButton filterCount={filterCount} disabled={create || upload} />
      )}
      {/* Search button */}
      {search && <SearchButton disabled={create || upload} />}
    </header>
  );
  //#endregion
}
