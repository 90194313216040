//#region IMPORTS
import {
  fetchFilters,
  setManageMode,
  storeSelectors,
  setStoreParams,
  fetchStoresAsync,
  updateStoreAsync,
  deleteStoreAsync,
} from "./storesSlice";
import {
  DELETE_STORE_SURE,
  CHANGE_STORE_COMMENT,
  UPLOAD_DATA_FOR_STORE,
  CREATE_OR_UPDATE_LANGUAGE,
  CREATE_OR_UPDATE_STOREFORMAT,
} from "../../models/_consts";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { closeFilterBar, openModal } from "../dashBoard/dashboardSlice";
import { fetchStoreFormatsAsync } from "../storeFormats/storeFormatSlice";
import { Store, StoreParams } from "../../models/store";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import StoresCustomFilters from "../../components/stores/StoresCustomFilters";
import StoreFormatContainer from "../../components/storeFormats/StoreFormatContainer";
import LanguageContainer from "../../components/languages/LanguageContainer";
import StoresDataUpload from "../../components/stores/StoresDataUpload";
import useAdminManager from "../../hooks/useAdminManager";
import AddYourComment from "../../components/modalTypes/AddYourComment";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import useFilterCount from "../../hooks/useFilterCount";
import MainContainer from "../../components/_layout/MainContainer";
import StoresTable from "../../components/stores/StoresTable";
import AreYouSure from "../../components/modalTypes/AreYouSure";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import MainModal from "../../components/_layout/MainModal";
import FilterBar from "../../components/filterBar/FilterBar";
import agent from "../../api/agent";
//#endregion

export default function StoresPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const stores = useAppSelector(storeSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { stores_loaded, filters_loaded, metaData } = useAppSelector(
    (state) => state.stores
  );
  const { storeFormats_loaded } = useAppSelector((state) => state.storeFormats);

  // Local State
  const [storeToChangeComment, setStoreToChangeComment] =
    useState<Store | null>(null);
  const [storeToDelete, setStoreToDelete] = useState<Store | null>(null);
  const [savingDataUpload, setSavingDataUpload] = useState<boolean>(false);
  const [createLanguage, setCreateLanguage] = useState<boolean>(false);
  const [savingComment, setSavingComment] = useState<boolean>(false);
  const [createFormat, setCreateFormat] = useState<boolean>(false);
  const [uploadMode, setUploadMode] = useState<boolean>(false);
  const isAdmin = useAdminManager(user);

  // Set Filters
  const { page, limit, sort, ...selectedFilters }: StoreParams = useAppSelector(
    (state) => state.stores.store_params
  );
  const filterCount = useFilterCount(selectedFilters);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    if (!filters_loaded) dispatch(fetchFilters());
  }, [filters_loaded, dispatch]);

  // Load stores
  useEffect(() => {
    !stores_loaded && dispatch(fetchStoresAsync());
  }, [stores_loaded, dispatch]);

  // Load storeformats
  useEffect(() => {
    !storeFormats_loaded && dispatch(fetchStoreFormatsAsync());
  }, [storeFormats_loaded, dispatch]);

  // Scrollblock
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    if (uploadMode) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [uploadMode, blockScroll, allowScroll]);

  // Close filterbar onleave
  useEffect(() => {
    return () => {
      dispatch(closeFilterBar());
    };
  }, [dispatch]);

  // Create
  const createNewStoreHandler = () => {
    history.push("stores/manage");
    dispatch(setManageMode(true));
    dispatch(openModal(false));
  };

  // Create new store format
  const toggleCreateStoreFormat = () => {
    if (createFormat) {
      dispatch(openModal(false));
      setCreateFormat(false);
    } else {
      dispatch(dispatch(openModal(CREATE_OR_UPDATE_STOREFORMAT)));
      setCreateFormat(true);
    }
  };

  // Create new language
  const toggleCreateLanguage = () => {
    if (createLanguage) {
      dispatch(openModal(false));
      setCreateLanguage(false);
    } else {
      dispatch(dispatch(openModal(CREATE_OR_UPDATE_LANGUAGE)));
      setCreateLanguage(true);
    }
  };

  // Upload stores
  const toggleUploadStores = () => {
    if (uploadMode) {
      dispatch(openModal(false));
      setUploadMode(false);
    } else {
      dispatch(dispatch(openModal(UPLOAD_DATA_FOR_STORE)));
      setUploadMode(true);
    }
  };

  // Set comment to change
  const changeCommentHandler = (store: Store) => {
    setStoreToChangeComment(store);
    dispatch(openModal(CHANGE_STORE_COMMENT));
  };

  // Update store with new comment
  const updateStoreCommentHandler = async (newComment: string) => {
    setSavingComment(true);
    if (storeToChangeComment) {
      const { data } = await agent.StoreManagement.detail(
        storeToChangeComment.id
      );
      data.comment = newComment;
      if (data.same_as_address) {
        delete data.delivery_addresses;
      }
      await dispatch(
        updateStoreAsync({ id: storeToChangeComment.id, values: data })
      );
      toast.success(
        `The comment ${data.store_name} has been succesfully changed.`
      );
    }
    cancelOrEndChangeComment();
  };

  // Cancel or end changing an comment
  const cancelOrEndChangeComment = () => {
    dispatch(openModal(false));
    setStoreToChangeComment(null);
    setSavingComment(false);
  };

  // Download all stores in a spreadsheet
  const downloadStoresSpreadsheet = async () => {
    try {
      await agent.StoreManagement.downloadAllAsSpreadsheet().then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "current-stores.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      );
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  // Delete store
  const storeDeleteModeHandler = (store: Store) => {
    setStoreToDelete(store);
    dispatch(openModal(DELETE_STORE_SURE));
  };

  const cancelDeleteStore = () => {
    dispatch(openModal(false));
    setStoreToDelete(null);
  };

  // Delete the shop by ID
  const deleteConfirmHandler = async () => {
    storeToDelete && (await dispatch(deleteStoreAsync(storeToDelete.id)));
    cancelDeleteStore();
  };
  //#endregion

  //#endregion

  //#region FAIL SAFE
  if (!stores_loaded && !filters_loaded) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal
        type={CHANGE_STORE_COMMENT}
        closeHandler={cancelOrEndChangeComment}
      >
        <AddYourComment
          isSaving={savingComment}
          defaultValue={storeToChangeComment?.comment}
          saveComment={(comment: string) => updateStoreCommentHandler(comment)}
        />
      </MainModal>

      <MainModal type={UPLOAD_DATA_FOR_STORE} closeHandler={toggleUploadStores}>
        <StoresDataUpload
          setSavingHandler={(value: boolean) => setSavingDataUpload(value)}
          cancelCUD={toggleUploadStores}
          saving={savingDataUpload}
          customer_id="1"
        />
      </MainModal>

      <MainModal
        type={CREATE_OR_UPDATE_STOREFORMAT}
        closeHandler={toggleCreateStoreFormat}
      >
        <StoreFormatContainer />
      </MainModal>

      <MainModal
        type={CREATE_OR_UPDATE_LANGUAGE}
        closeHandler={toggleCreateLanguage}
      >
        <LanguageContainer />
      </MainModal>

      <MainModal type={DELETE_STORE_SURE} closeHandler={cancelDeleteStore}>
        <AreYouSure
          confirmHandler={deleteConfirmHandler}
          closeHandler={cancelDeleteStore}
          message={`Are you sure you want to delete ${storeToDelete?.store_name}`}
          title="Delete store?"
        />
      </MainModal>

      {/* BODY */}
      <MainContainer>
        <FilterBar
          selectedFilters={selectedFilters}
          setParamsHandler={(values: any) => {
            dispatch(setStoreParams(values));
          }}
          directTo="/stores"
        >
          <StoresCustomFilters />
        </FilterBar>

        <PageHeader
          title={t("rituals.storeManager")}
          createButtonValue={t("rituals.add")}
          uploadClickHandler={toggleUploadStores}
          downloadClickHandler={downloadStoresSpreadsheet}
          createClickHandler={createNewStoreHandler}
          uploadButtonValue={t("rituals.uploadExcel")}
          filterCount={filterCount}
          isAdmin={isAdmin}
          download={true}
          filter={true}
          extraButtons={
            <div>
              <button
                onClick={toggleCreateStoreFormat}
                type="button"
                className="inline-flex items-center gap-x-2 px-3.5 py-3 font-semibold text-slate-600 hover:text-slate-400"
              >
                <EyeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                {t("rituals.storeFormats")}
              </button>
              <button
                onClick={toggleCreateLanguage}
                type="button"
                className="inline-flex items-center gap-x-2 px-3.5 py-3 font-semibold text-slate-600 hover:text-slate-400"
              >
                <EyeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                {t("rituals.language")}
              </button>
            </div>
          }
        />

        <StoresTable
          commentHandler={(store: Store) => changeCommentHandler(store)}
          deleteHandler={(store: Store) => storeDeleteModeHandler(store)}
          filtersLoaded={filters_loaded}
          storesLoaded={stores_loaded}
          metaData={metaData}
          stores={stores}
        />
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
