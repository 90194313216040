//#region IMPORTS
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { StoreDeliveryAddress } from "../../models/store";
import { Tooltip } from "react-tooltip";
import MainDetailItem from "../_layout/MainDetailItem";
//#endregion

//#region INTERFACE
interface Props {
  deleteHandler: () => void;
  editHandler: () => void;
  manageMode?: boolean;
  address: StoreDeliveryAddress;
}
//#endregion

export default function StoreManageFormAddressListItem({
  deleteHandler,
  editHandler,
  manageMode = false,
  address,
}: Props) {
  //#region RENDER
  return (
    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
      <div className="flex w-0 flex-1 items-center">
        <div className="ml-4 min-w-0">
          <div className="flex gap-2">
            <MainDetailItem value={address.address_1} />
            <MainDetailItem value={address.address_2} />
            <MainDetailItem value={address.address_3} />
          </div>
          <MainDetailItem value={`${address.postal_code} ${address.city}`} />
          <MainDetailItem value={address.country_code} />

          <div className="flex gap-8 mt-2 text-gray-500">
            {address.activation_date && (
              <div>
                <label htmlFor="active_at" className="mr-2 text-xs">
                  Active from:
                </label>
                <span id="active_at" className="text-xs">
                  {address.activation_date}
                </span>
              </div>
            )}

            <div>
              <input
                checked={address.current_address}
                disabled
                id="current_address"
                name="current_address"
                type="checkbox"
                aria-describedby="is_active"
                className="h-4 w-4 rounded border-gray-300 text-gray-500"
              />
              <label htmlFor="current_address" className="ml-2 text-xs">
                Current address
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-4 flex-shrink-0">
        <div className="font-medium text-slate-600 hover:text-slate-500">
          {manageMode && (
            <div className="flex gap-4">
              <PencilSquareIcon
                data-tooltip-id={`${address.id}Edit`}
                data-tooltip-content="Edit"
                role="button"
                className="w-6 h-6 text-slate-600"
                onClick={editHandler}
              />
              <TrashIcon
                data-tooltip-id={`${address.id}Delete`}
                data-tooltip-content="Delete"
                role="button"
                className="w-6 h-6 text-red-500"
                onClick={deleteHandler}
              />
              <Tooltip id={`${address.id}Edit`} />
              <Tooltip id={`${address.id}Delete`} />
            </div>
          )}
        </div>
      </div>
    </li>
  );
  //#endregion
}
