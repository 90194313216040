//#region IMPORTS
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { setManageMode } from "./storesSlice";
import { StoreDetail } from "../../models/store";
import { closeModal } from "../dashBoard/dashboardSlice";
import { toast } from "react-toastify";
import StoreManageForm from "../../components/storeManage/StoreManageForm";
import useAdminManager from "../../hooks/useAdminManager";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import MainContainer from "../../components/_layout/MainContainer";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import agent from "../../api/agent";
//#endregion

export default function StoreManage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { manageMode } = useAppSelector((state) => state.stores);
  const { user } = useAppSelector((state) => state.account);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState<StoreDetail>();

  // Local State
  const isAdmin = useAdminManager(user);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    if (id && manageMode) {
      setUpdateMode(true);
      setCreateMode(false);
    } else if (!id && manageMode) {
      setUpdateMode(false);
      setCreateMode(true);
    } else if (id && !manageMode) {
      setUpdateMode(false);
      setCreateMode(false);
    } else {
      history.push("/stores");
      toast.error("no store selected");
    }
  }, [id, manageMode, history]);

  // Fetch ShopDetail
  useEffect(() => {
    const fetchStoreDetail = async () => {
      setLoading(true);
      try {
        const { data } = await agent.StoreManagement.detail(parseInt(id));
        setStore(data);
        setLoading(false);
      } catch (error) {
        toast.error(`${error}`);
        setLoading(false);
      }
    };
    id && fetchStoreDetail();
  }, [id, dispatch]);

  // Close form
  const closeFormHandler = () => {
    history.push("/stores");
    dispatch(closeModal());
  };

  //#endregion

  if (loading) return <LoadingSpinner />;

  return (
    <MainContainer>
      <PageHeader
        title={
          createMode
            ? t("rituals.createStore")
            : updateMode && store
            ? t("rituals.edit") + " " + store.store_name
            : store
            ? store.store_name
            : "detail"
        }
        isAdmin={isAdmin}
        extraButtons={
          !createMode && !updateMode ? (
            <button
              type="button"
              onClick={() => dispatch(setManageMode(true))}
              className="inline-flex items-center gap-x-2 rounded-md bg-amber-600 px-3.5 py-2 text-1xl font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
            >
              <PencilSquareIcon
                aria-hidden="true"
                className="-ml-0.5 h-5 w-5"
              />
              {t("rituals.edit")}
            </button>
          ) : null
        }
      >
        <GoBackButton />
      </PageHeader>

      <section className="store-page__content">
        <StoreManageForm
          store={store}
          manageMode={manageMode}
          closeHandler={closeFormHandler}
        />
      </section>
    </MainContainer>
  );
}
