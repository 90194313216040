//#region INTERFACE
interface Props {
  value?: string | number | null;
  label?: string;
}
//#endregion

export default function MainDetailItem({ value, label }: Props) {
  //#region RENDER
  return value && value !== "-" ? (
    <div>
      {label && (
        <label className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
          {label}
        </label>
      )}
      <div className="mt-1 text-sm text-gray-900 sm:col-span-2">{value}</div>
    </div>
  ) : null;
  //#endregion
}
