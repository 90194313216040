//#region IMPORTS
import { useTranslation } from "react-i18next";
//#endregion

//#region INTERFACE
interface Props {
  cancelHandler?: () => void;
  isSubmitting: boolean;
  isValid: boolean;
}
//#endregion

export default function MainSaveButtons({
  isSubmitting,
  isValid,
  cancelHandler,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();
  //#endregion

  //#region LOGIC
  const cancelAction = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    cancelHandler && cancelHandler();
  };
  //#endregion

  //#region RENDER
  return (
    <div className="justify-end flex gap-4">
      {cancelHandler && (
        <button
          onClick={(e) => cancelAction(e)}
          className="bg-pink-100 hover:bg-pink-200 flex justify-center opacity-100 rounded-md px-6 py-1 text-sm font-semibold leading-6 text-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 shadow-sm"
        >
          {t("rituals.cancel")}
        </button>
      )}

      <input
        value={isSubmitting ? t("rituals.saving") : t("rituals.save")}
        type="submit"
        className={
          (isValid
            ? "bg-emerald-600 hover:bg-emerald-500"
            : "bg-pink-600 hover:bg-pink-500") +
          " " +
          "flex justify-center opacity-100 rounded-md px-6 py-1 text-sm font-semibold leading-6 text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 shadow-sm"
        }
      />
    </div>
  );
  //#endregion
}
