//#region IMPORTS
import {
  StoreDetail,
  StoreRequest,
  StoreDeliveryAddress,
} from "../../models/store";
import {
  createStoreAsync,
  updateStoreAsync,
} from "../../features/stores/storesSlice";
import {
  languageSelectors,
  fetchLanguagesAsync,
} from "../../features/languages/languageSlice";
import {
  storeFormatSelectors,
  fetchStoreFormatsAsync,
} from "../../features/storeFormats/storeFormatSlice";
import {
  STORE_ADDRESS_MODAL,
  STORE_FORM_MODAL_SAFETY,
  STORE_ADDRESS_MODAL_SAFETY,
} from "../../models/_consts";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { closeModal, openModal } from "../../features/dashBoard/dashboardSlice";
import { useTranslation } from "react-i18next";
import { StoreFormat } from "../../models/storeFormat";
import { Language } from "../../models/language";
import { useForm } from "react-hook-form";
import StoreManageFormAddressForm from "./StoreManageFormAddressForm";
import StoreManageFormAddressList from "./StoreManageFormAddressList";
import StoreManageFormLabel from "./StoreManageFormLabel";
import MainConfirmPanel from "../_layout/MainConfirmPanel";
import MainSaveButtons from "../_layout/MainSaveButtons";
import Modal from "../_layout/MainModal";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
//#endregion

//#region INTERFACE
interface Props {
  closeHandler: () => void;
  manageMode?: boolean;
  store?: StoreDetail;
}
//#endregion

export default function StoreManageForm({
  closeHandler,
  manageMode = true,
  store,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { languages_loaded } = useAppSelector(
    (state) => state.languagesManagement
  );
  const { storeFormats_loaded } = useAppSelector((state) => state.storeFormats);
  const storeFormatsToSelect = useAppSelector(storeFormatSelectors.selectAll);
  const languagesToSelect = useAppSelector(languageSelectors.selectAll);

  // Local state
  const [selectedAddresses, setSelectedAddresses] = useState<
    StoreDeliveryAddress[]
  >([]);
  // const [sameDelivery, setSameDelivery] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] =
    useState<StoreDeliveryAddress | null>(null);

  // UseForm
  const {
    reset,
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { isSubmitting, errors, isValid, isDirty },
  } = useForm<StoreDetail>({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Load form store data
  useEffect(() => {
    store && reset(store);
    store && setSelectedAddresses(store.delivery_addresses);
    // store && setSameDelivery(store.same_as_address);
  }, [store, reset]);

  // Fetch languages onload
  useEffect(() => {
    const fetchLanguages = async () => {
      await dispatch(fetchLanguagesAsync());
    };
    !languages_loaded && fetchLanguages();
  }, [languages_loaded, dispatch]);

  // Fetch storeFormats onload
  useEffect(() => {
    const fetchStoreFormats = async () => {
      await dispatch(fetchStoreFormatsAsync());
    };
    !storeFormats_loaded && fetchStoreFormats();
  }, [storeFormats_loaded, dispatch]);

  // Submit
  async function submitForm({
    active,
    address_1,
    address_2,
    address_3,
    store_name,
    store_type,
    external_store_id,
    city,
    country_code,
    postal_code,
    opening_date,
    store_format,
    language,
    billing_id,
    comment,
    contact_first_name,
    contact_last_name,
    contact_email,
    contact_phone,
    delivery_addresses,
  }: StoreDetail) {
    // Extra validation

    if (!delivery_addresses) {
      setError("delivery_addresses", {
        type: "required",
        message: "A store needs at least one delivery address.",
      });
      return;
    }

    // Prepare request
    const request: StoreRequest = {
      customer_id: "1",
      store_name,
      store_type,
      external_store_id,
      address_1,
      address_2,
      address_3,
      city,
      country_code,
      postal_code,
      opening_date,
      store_format_id:
        storeFormatsToSelect.find((sf2s) => sf2s.name === store_format?.name)
          ?.id ?? 0,
      store_format_name: store_format?.name,
      language,
      billing_id,
      comment,
      active,
      contact_first_name,
      contact_last_name,
      contact_email,
      contact_phone,
      same_as_address: false,
      delivery_addresses: selectedAddresses,
    };

    //// Manipulate data to match with server requests
    // Overwrite current billing address
    const currentDeliveryAddress = selectedAddresses.find(
      (address) => address.current_address
    );
    if (currentDeliveryAddress) {
      request.address_1 = currentDeliveryAddress.address_1;
      request.address_2 = currentDeliveryAddress.address_2;
      request.address_3 = currentDeliveryAddress.address_3;
      request.city = currentDeliveryAddress.city;
      request.country_code = currentDeliveryAddress.country_code;
      request.postal_code = currentDeliveryAddress.postal_code;
    }

    // // Remove the delivery addresses if sameDelivery === true
    // if (sameDelivery) {
    //   delete request.delivery_addresses;
    // }

    // Remove id from delivery address it is zero
    if (request.delivery_addresses && request.delivery_addresses?.length > 0) {
      const cleanedDeliveryAddresses = request.delivery_addresses.map(
        (item: StoreDeliveryAddress) => {
          if (item.id === 0) {
            // @ts-ignore
            delete item.id;
          }
          return item;
        }
      );
      request.delivery_addresses = { ...cleanedDeliveryAddresses };
    }

    // Send request
    if (store && store.id !== 0) {
      !isSubmitting &&
        (await dispatch(updateStoreAsync({ id: store.id, values: request })));
    } else {
      !isSubmitting && (await dispatch(createStoreAsync({ values: request })));
    }
    closeHandler();
  }

  // Add or update address
  const addOrUpdateAddressList = (item: StoreDeliveryAddress) => {
    clearErrors();
    let newSelectedAddresses: StoreDeliveryAddress[] = [];
    newSelectedAddresses = [
      item,
      ...selectedAddresses.filter((add) => add.id !== item.id),
    ];
    setSelectedAddresses(newSelectedAddresses);
    setValue("delivery_addresses", newSelectedAddresses, { shouldDirty: true });
    setSelectedAddress(null);
  };

  // Remove address
  const deleteAddressConfirmHandler = (id: number) => {
    let newSelectedAddresses: StoreDeliveryAddress[] = [];
    newSelectedAddresses = selectedAddresses.filter((add) => add.id !== id);
    setSelectedAddresses(newSelectedAddresses);
    setValue("delivery_addresses", newSelectedAddresses, { shouldDirty: true });
    setSelectedAddress(null);
  };

  // UI Resetter
  const resetter = () => {
    dispatch(closeModal());
    setSelectedAddress(null);
  };

  // Close form check dirty
  const closeFormAndCheckIfDirty = () => {
    isDirty ? dispatch(openModal(STORE_FORM_MODAL_SAFETY)) : closeHandler();
  };

  // Set same as delivery
  // const handleSameAsDelivery = (e: any) => {
  //   setSameDelivery(e.target.checked);
  // };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Modals */}
      <Modal
        closeHandler={() => dispatch(closeModal())}
        type={STORE_ADDRESS_MODAL}
      >
        <Fragment>
          <div className="mt-3 mb-3 text-center sm:text-left">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {selectedAddress
                ? t("rituals.editAddress")
                : t("rituals.createNewAddress")}
            </h3>
          </div>
          <StoreManageFormAddressForm
            changeAddressesHandler={(values: StoreDeliveryAddress) =>
              addOrUpdateAddressList(values)
            }
            address={selectedAddress}
            closeHandler={resetter}
          />
        </Fragment>
      </Modal>

      {selectedAddress && (
        <Modal
          closeHandler={() => dispatch(closeModal())}
          type={STORE_ADDRESS_MODAL_SAFETY}
        >
          <MainConfirmPanel
            closeHandler={resetter}
            confirmHandler={() =>
              selectedAddress.id &&
              deleteAddressConfirmHandler(selectedAddress.id)
            }
            title={t("rituals.removeAddress?")}
          />
        </Modal>
      )}

      <Modal
        closeHandler={() => dispatch(closeModal())}
        type={STORE_FORM_MODAL_SAFETY}
      >
        <MainConfirmPanel
          closeHandler={resetter}
          confirmHandler={closeHandler}
          title={t("rituals.cancelWithoutSaving?")}
          message={t(
            "rituals.allChangesWillBeLostAreYouSureYouWantToLeaveWithoutSaving?"
          )}
        />
      </Modal>

      {/* Form body */}
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="space-y-12">
          {/* INFO SECTION */}
          <section className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <header>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t("rituals.storeInformation")}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {t(
                  "rituals.theStoresKeyDetailsAndAdditionalRelevantInformation"
                )}
              </p>
            </header>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              {/* store_name */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="store_name"
                  labelText={t("rituals.storeName")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      placeholder="e.g. Rituals Gent"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("store_name", {
                        required: t("rituals.theStoreNameFieldIsRequired"),
                      })}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.store_name}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* external_store_id */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="external_store_id"
                  labelText={t("rituals.storeId")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      placeholder="e.g. 6666"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("external_store_id", {
                        required: t("rituals.theStoreIdFieldIsRequired"),
                      })}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.external_store_id}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* billing_id */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="billing_id"
                  labelText={t("rituals.billingID")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      placeholder="e.g. 666-666"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("billing_id", {
                        required: t("rituals.theBillingIdFieldIsRequired"),
                      })}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.billing_id}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* store_format.name */}
              {storeFormatsToSelect && (
                <fieldset className="sm:col-span-3">
                  <StoreManageFormLabel
                    errors={errors}
                    fieldName="store_format.name"
                    labelText={t("rituals.storeFormat")}
                  />
                  <div className="mt-2">
                    {manageMode ? (
                      <select
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                        {...register("store_format.name", {
                          required: t("rituals.theStoreFormatFieldIsRequired"),
                        })}
                      >
                        {storeFormatsToSelect.map(
                          (storeFormat: StoreFormat) => (
                            <option
                              key={storeFormat.id}
                              value={storeFormat.name}
                            >
                              {storeFormat.name}
                            </option>
                          )
                        )}
                      </select>
                    ) : (
                      <p className="text-slate-600 font-medium">
                        {store?.store_format ? store?.store_format.name : "No storeformat found. Please edit this store."}
                      </p>
                    ) }
                  </div>
                </fieldset>
              )}

              {/* language */}
              {languagesToSelect && (
                <fieldset className="sm:col-span-3">
                  <StoreManageFormLabel
                    errors={errors}
                    fieldName="language"
                    labelText={t("rituals.language")}
                  />
                  <div className="mt-2">
                    {manageMode ? (
                      <select
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                        {...register("language", {
                          required: t("rituals.languageFieldIsRequired"),
                        })}
                      >
                        {languagesToSelect.map((language: Language) => (
                          <option key={language.id} value={language.code}>
                            {language.code}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <p className="text-slate-600 font-medium">
                        {store?.language}
                      </p>
                    )}
                  </div>
                </fieldset>
              )}

              {/* comment */}
              <fieldset className="sm:col-span-4">
                <StoreManageFormLabel
                  fieldName="comment"
                  labelText={t("rituals.comment")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("comment")}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.comment}
                    </p>
                  )}
                </div>
              </fieldset>
            </div>
          </section>

          {/* CONTACT PERSON SECTION */}
          <section className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <header>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t("rituals.contactInformation")}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {t("rituals.theStoresContactInformation")}
              </p>
            </header>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              {/* contact_first_name */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="contact_first_name"
                  labelText={t("rituals.contactFirstName")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      placeholder="e.g. John"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("contact_first_name")}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.contact_first_name}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* contact_last_name */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="contact_last_name"
                  labelText={t("rituals.contactLastName")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      placeholder="e.g. Doe"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("contact_last_name")}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.contact_last_name}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* contact_email */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="contact_email"
                  labelText={t("rituals.contactEmail")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="email"
                      placeholder="e.g. john.doe@rituals.com"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("contact_email", {
                        required: t("rituals.theContactEmailIsRequired"),
                      })}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.contact_email}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* contact_phone */}
              <fieldset className="sm:col-span-3">
                <StoreManageFormLabel
                  fieldName="contact_phone"
                  labelText={t("rituals.contactPhone")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="text"
                      placeholder="09 252 32 32"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                      {...register("contact_phone")}
                    />
                  ) : (
                    <p className="text-slate-600 font-medium">
                      {store?.contact_phone}
                    </p>
                  )}
                </div>
              </fieldset>
            </div>
          </section>

          {/* SETTINGS SECTION */}
          <section className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <header>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t("rituals.storeSettings")}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {t(
                  "rituals.provideConfigurationDetailsAndPreferencesForTheStoreSettings"
                )}
              </p>
            </header>

            <div className="max-w-2xl space-y-10 md:col-span-2">
              {/* opening_date */}
              <fieldset>
                <StoreManageFormLabel
                  fieldName="opening_date"
                  labelText={t("rituals.openingDate")}
                  errors={errors}
                />
                <div className="mt-2">
                  {manageMode ? (
                    <input
                      type="date"
                      placeholder="04/05/2024"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      {...register("opening_date", {
                        required: t("rituals.theOpeningDateIsRequired"),
                      })}
                    />
                  ) : (
                    <p className="text-slate-900 font-medium">
                      {store?.opening_date}
                    </p>
                  )}
                </div>
              </fieldset>

              {/* active */}
              <fieldset>
                <div className="mt-2 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        disabled={!manageMode}
                        type="checkbox"
                        className={classNames(
                          manageMode
                            ? "text-amber-500 focus:ring-amber-500"
                            : "text-gray-500",
                          "h-4 w-4 rounded border-gray-300"
                        )}
                        {...register("active")}
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="is_active"
                        className="font-medium text-gray-900"
                      >
                        {t("rituals.active")}
                      </label>
                      <p className="text-gray-500">
                        {t("rituals.checkThisOptionToOverrideActivationDate")}
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </section>

          {/* ADDRESS SECTION */}
          <section className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <header>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t("rituals.addressList")}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {t("rituals.detailsAndKeyInformationForTheAddressList")}
              </p>
              {errors.delivery_addresses && (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {/* @ts-ignore */}
                  {errors.delivery_addresses.message}
                </span>
              )}
            </header>
            <div className="max-w-2xl space-y-10 md:col-span-2">
              {/* delivery_addresses */}
              <fieldset>
                <StoreManageFormAddressList
                  same_as_address={false}
                  selectAddressHandler={(values) => setSelectedAddress(values)}
                  addresses={selectedAddresses}
                  updateMode={!!store}
                  manageMode={manageMode}
                />
              </fieldset>

              {/* same_as_delivery */}
              {/* <fieldset>
                <div className="mt-2 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        type="checkbox"
                        disabled={!manageMode}
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-slate-600"
                        defaultChecked={store ? store.same_as_address : false}
                        onChange={(e: any) => handleSameAsDelivery(e)}
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="same_as_address"
                        className="font-medium text-gray-900"
                      >
                        Current delivery address is same as billing address?
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset> */}
            </div>
          </section>
        </div>

        {manageMode ? (
          <div className="mt-6 mb-6 flex items-center justify-end gap-x-6">
            <MainSaveButtons
              isSubmitting={isSubmitting}
              isValid={isValid}
              cancelHandler={closeFormAndCheckIfDirty}
            />
          </div>
        ) : null}
      </form>
    </Fragment>
  );

  //#endregion
}
