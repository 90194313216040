//#region IMPORTS
import {
  fetchShippableOrdersAsync,
  fetchApprovableOrdersAsync,
} from "../orders/ordersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { fetchBasketReasonsAsync } from "../basketReason/basketReasonSlice";
import { Redirect, Route, Switch } from "react-router-dom";
import { useEffect, useRef } from "react";
import { fetchPromosAsync } from "../promos/promosSlice";
import { useTranslation } from "react-i18next";
import { closeDash } from "./dashboardSlice";
import { toast } from "react-toastify";
import ProductVersionsDetailPage from "../productVersions/ProductVersionsDetailPage";
import TranslationManagerPage from "../translationManager/TranslationManagerPage";
import ProductVersionsPage from "../productVersions/ProductVersionsPage";
import ProductsDetailPage from "../products/ProductsDetailPage";
import DashboardSlideBox from "./DashboardSlideBox";
import OrdersHistoryPage from "../orders/OrdersHistoryPage";
import OrdersApprovePage from "../orders/OrdersApprovePage";
import OrdersShippedPage from "../orders/OrdersShippedPage";
import OrdersPendingPage from "../orders/OrdersPendingPage";
import OrdersManagePage from "../orders/OrdersManagePage";
import usePickerManager from "../../hooks/usePickerManager";
import CheckoutConfirm from "../checkout/CheckoutConfirm";
import useAdminManager from "../../hooks/useAdminManager";
import OrderDetailPage from "../orders/OrderDetailPage";
import BackOrderPage from "../backorder/BackOrderPage";
import ItemBigImage from "../../components/itemsGrid/ItemBigImage";
import CheckoutPage from "../checkout/CheckoutPage";
import ProductsPage from "../products/ProductsPage";
import useHQmanager from "../../hooks/useHQmanager";
import DashBoardNav from "./DashBoardNav";
import PickerRoute from "../../components/routes/PickerRoute";
import StoreManage from "../stores/StoreManage";
import FeaturePage from "../faq/FeaturePage";
import StoresPage from "../stores/StoresPage";
import BasketPage from "../basket/BasketPage";
import PromosPage from "../promos/PromosPage";
import AdminRoute from "../../components/routes/AdminRoute";
import StoreRoute from "../../components/routes/StoreRoute";
import UsersPage from "../users/UsersPage";
import HqRoute from "../../components/routes/HqRoute";
import NavBar from "../../components/navbar/NavBar";
import Home from "../home/Home";
import "./Dashboard.css";
//#endregion

export default function Dashboard() {
  //#region SETUP
  // Ref
  const toastId: any = useRef();

  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const { promosLoaded } = useAppSelector((state) => state.promos);

  // Roles
  const { user } = useAppSelector((state) => state.account);
  const { refreshingTransmart } = useAppSelector((state) => state.promos);
  const isPicker = usePickerManager(user);
  const isAdmin = useAdminManager(user);
  const isHq = useHQmanager(user);

  // Check device
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  //#endregion

  //#region LOGIC
  // Close Dashboard on smaller screens
  useEffect(() => {
    if (windowSize.current[0] < 996) {
      dispatch(closeDash());
    }
  }, [dispatch]);

  // Load promos
  useEffect(() => {
    if (!promosLoaded) {
      dispatch(fetchPromosAsync());
    }
  }, [promosLoaded, dispatch]);

  // Load basket reasons
  useEffect(() => {
    dispatch(fetchBasketReasonsAsync());
  }, [dispatch]);

  // Load role specific orders
  useEffect(() => {
    const fetchRoleSpecificOrders = async () => {
      (isAdmin || isHq) && (await dispatch(fetchApprovableOrdersAsync()));
      (isAdmin || isPicker) && (await dispatch(fetchShippableOrdersAsync()));
    };
    fetchRoleSpecificOrders();
  }, [dispatch, isAdmin, isHq, isPicker]);

  // ShowUpdating badge
  useEffect(() => {
    if (refreshingTransmart) {
      toastId.current = toast("Updating transmart connection", {
        autoClose: false,
        theme: "dark",
        type: "info",
        closeOnClick: false,
      });
    } else {
      toast.update(toastId.current, {
        render: "Update succesfull",
        type: "success",
        autoClose: 5000,
      });
    }
  }, [refreshingTransmart]);
  //#endregion

  //#region RENDER
  return (
    <main className="dashboard">
      <DashBoardNav />

      <header className="dashboard__header">
        <NavBar />
      </header>
      <section className="dashboard__content">
        <DashboardSlideBox>
          <Switch>
            {/* USERS */}
            <AdminRoute exact path="/users" component={UsersPage} />

            {/* TRANSLATIONMANAGER */}
            <AdminRoute
              exact
              path="/translations"
              component={TranslationManagerPage}
            />

            {/* PRODUCTVERSIONS */}
            <AdminRoute
              path="/productversions/:id"
              component={ProductVersionsDetailPage}
            />
            <AdminRoute
              exact
              path="/productversions"
              component={ProductVersionsPage}
            />

            {/* PRODUCTS */}
            <AdminRoute path="/products/:id" component={ProductsDetailPage} />
            <AdminRoute exact path="/products" component={ProductsPage} />

            {/* STOREMANAGER */}
            <StoreRoute exact path="/stores/manage" component={StoreManage} />
            <StoreRoute path="/stores/manage/:id" component={StoreManage} />
            <StoreRoute exact path="/stores" component={StoresPage} />

            {/* PROMOS */}
            <Route exact path="/promos" component={PromosPage} />

            {/* ORDERS TO APPROVE */}
            <HqRoute path="/approve/order/:id">
              <OrderDetailPage />
            </HqRoute>
            <HqRoute path="/approve/:code">
              <OrdersApprovePage title={t("rituals.approvableBackOrders")} />
            </HqRoute>
            <HqRoute path="/approve">
              <OrdersApprovePage title={t("rituals.approvableBackOrders")} />
            </HqRoute>

            {/* CHECKOUT */}
            <Route path="/checkout/:id" component={CheckoutConfirm} />
            <Route path="/checkout" component={CheckoutPage} />

            {/* BASKET */}
            <Route path="/basket" component={BasketPage} />

            {/* ORDERS WITH SUBORDERS SHIPPED */}
            <Route path="/shipped" exact>
              <OrdersShippedPage title={t("rituals.shippedBackOrders")} />
            </Route>

            {/* ORDERS WITH SUBORDERS PENDING */}
            <Route path="/pending" exact>
              <OrdersPendingPage title={t("rituals.pendingBackOrders")} />
            </Route>

            {/* ORDERS WITH SUBORDERS ORDERED */}
            <PickerRoute path="/manage" exact>
              <OrdersManagePage title={t("rituals.orderedBackOrders")} />
            </PickerRoute>

            {/* ALL ORDERS WITH SUBORDERS */}
            <Route path="/orders/:id" component={OrderDetailPage} />
            <Route path="/orders" exact>
              <OrdersHistoryPage
                title={
                  !isAdmin && !isPicker && !isHq
                    ? t("rituals.orderHistory")
                    : t("rituals.allOrders")
                }
              />
            </Route>

            {/* FEATURE PAGE */}
            <Route path="/featurelist" exact component={FeaturePage} />

            {/* BACKORDER */}
            <Route path="/backorder/promo/:id" component={BackOrderPage} />

            {/* BIGIMAGE */}
            <Route path="/image/:id" component={ItemBigImage} />

            {/* DEFAULT */}
            <Route path="/" exact component={Home} />
            <Redirect to="/not-found" />
          </Switch>
        </DashboardSlideBox>
      </section>
    </main>
  );
  //#endregion
}
