//#region IMPORTS
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import { Order } from "../../models/order";
import OrderDetailPageHeaderStoreInfo from "./OrderDetailPageHeaderStoreInfo";
import GoBackButton from "../genericButtons/goBackButton/GoBackButton";
import SquareButton from "../genericButtons/squareButton/SquareButton";
//#endregion

//#region INTERFACE
interface Props {
  detailgroupChecker: (value: string) => boolean;
  toggleFilter: (filterName: string) => void;
  isHQTeam: boolean;
  isPicker: boolean;
  isAdmin: boolean;
  order: Order;
}
//#endregion

export default function OrderDetailPageHeader({
  detailgroupChecker,
  toggleFilter,
  isHQTeam,
  isPicker,
  isAdmin,
  order,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();
  //#endregion

  //#region RENDER
  return (
    <header className="border-b-2 border-slate-900 mb-8">
      {/* Order info */}
      <div className="flex justify-between gap-4 pl-2 mb-4">
        <GoBackButton />
        {order.store && (
          <Fragment>
            <div
              className="grow hover:cursor-pointer"
              onClick={() => history.push("/orders")}
            >
              <p className="text-2xl lg:text-4xl">
                <b>{order.store.store_name}</b>
              </p>
              <p className="text-xl lg:text-3xl">{order.order_code}</p>
              <p className="text-lg lg:text-2xl">{order.store.language}</p>
            </div>

            {/* Store info */}
            <OrderDetailPageHeaderStoreInfo order={order} />
          </Fragment>
        )}
      </div>

      {/* Comment box*/}
      {order.comment !== "" &&
      order.comment !== "no comment" &&
      order.comment !== "Initial order" ? (
        <article className="orders-detail-page__header__comment-box">
          <h2>
            {t("rituals.extraComment")}: <strong>{order.comment}</strong>
          </h2>
        </article>
      ) : null}

      {/* View switcher */}
      {/* By Printer */}
      <div className="flex items-center justify-between">
        {isAdmin || isPicker || isHQTeam ? (
          <SquareButton
            value={
              detailgroupChecker("produced_by")
                ? t("rituals.listView")
                : t("rituals.viewByPrinter")
            }
            color={detailgroupChecker("produced_by") ? "black" : "#fbb033"}
            clickHandler={() => toggleFilter("produced_by")}
            marginBottom="1rem"
            marginTop="1rem"
          />
        ) : null}

        {/* By parcel */}
        {order && order.sub_orders.length > 1 && (
          <div className="flex items-center gap-16">
            {detailgroupChecker("sub_order_id") ? null : (
              <h2>
                <strong>
                  {t("rituals.thisOrderIncludes$VAR$Parcels").replace(
                    "$VAR$",
                    order.sub_orders.length.toString()
                  )}
                </strong>
              </h2>
            )}

            <SquareButton
              value={
                detailgroupChecker("sub_order_id")
                  ? t("rituals.listView")
                  : t("rituals.viewByParcel")
              }
              color={detailgroupChecker("sub_order_id") ? "black" : "#fbb033"}
              clickHandler={() => toggleFilter("sub_order_id")}
              marginBottom="1rem"
              marginTop="1rem"
            />
          </div>
        )}
      </div>
    </header>
  );
  //#endregion
}
