//#region IMPORTS
import {
  setManageMode,
  updateStoreAsync,
} from "../../features/stores/storesSlice";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { Store } from "../../models/store";
import StoresTableRowActionsMenu from "./StoresTableRowActionsMenu";
import ToggleButton from "../genericButtons/toggleButton/ToggleButton";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  commentHandler: (store: Store) => void;
  deleteHandler: (store: Store) => void;
  isHQTeam: boolean;
  isPicker: boolean;
  isAdmin: boolean;
  store: Store;
}
//#endregion

export default function StoresTableRowActions({
  commentHandler,
  deleteHandler,
  isHQTeam,
  isPicker,
  isAdmin,
  store,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  //#endregion

  //#region LOGIC
  // Redirect to detail page
  const redirectToDetailPage = () => {
    history.push(`/stores/manage/${store.id}`, true);
    dispatch(setManageMode(false));
  };

  // Redirect to edit page
  const redirectToEditPage = () => {
    history.push(`/stores/manage/${store.id}`, true);
    dispatch(setManageMode(true));
  };

  // DeleteHandler

  // Fast toggle active
  const storeActiveHandler = async (storeId: number) => {
    const { data } = await agent.StoreManagement.detail(storeId);
    data.active = !data.active;
    if (data.same_as_address) {
      delete data.delivery_addresses;
    }
    await dispatch(updateStoreAsync({ id: storeId, values: data }));
    toast.success(
      `${data.store_name} has been set ${data.active ? "active" : "inactive"}.`
    );
  };
  //#endregion

  return (
    <td className="whitespace-nowrap px-2 pl-6 py-3.5 text-left text-sm text-gray-500">
      <div className="flex items-center gap-4 justify-between">
        <div className="flex items-center gap-4">
          {/* Comment bubble */}
          <button
            onClick={() => commentHandler(store)}
            disabled={!isAdmin && !isHQTeam}
          >
            <ChatBubbleLeftIcon
              className="w-6"
              data-tooltip-id={store.id.toString()}
              data-tooltip-content={store.comment ? store.comment : null}
              color={
                store.comment !== null &&
                store.comment !== "-" &&
                store.comment !== ""
                  ? "#fbb033"
                  : "#becbd3"
              }
            />
            <Tooltip id={store.id.toString()} />
          </button>

          {/* Toggle approve or decline for batch */}
          <ToggleButton
            changeColorByState={true}
            labelRight={true}
            startValue={store.active}
            switchDisabled={!isAdmin}
            label={
              store.active ? t("rituals.activeStore") : t("rituals.closedStore")
            }
            toggleHandler={() => storeActiveHandler(store.id)}
          />
        </div>

        <StoresTableRowActionsMenu
          detailhandler={redirectToDetailPage}
          deletehandler={() => deleteHandler(store)}
          edithandler={redirectToEditPage}
          isPicker={isPicker}
          isHQTeam={isHQTeam}
          isAdmin={isAdmin}
        />
      </div>
    </td>
  );
}
