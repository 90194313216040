//#region IMPORTS
import { StoreDeliveryAddress } from "../../models/store";
import { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { Country } from "../../models/country";
import { format } from "date-fns";
import { toast } from "react-toastify";
import agent from "../../api/agent";
import MainSaveButtons from "../_layout/MainSaveButtons";
//#endregion

//#region INTERFACE
interface Props {
  changeAddressesHandler: (values: StoreDeliveryAddress) => void;
  closeHandler: () => void;
  address: StoreDeliveryAddress | null;
}
//#endregion

export default function StoreForm({
  changeAddressesHandler,
  closeHandler,
  address,
}: Props) {
  //#region SETUP
  // State
  const [countriesToSelectLoaded, setCountriesToSelectLoaded] =
    useState<boolean>(false);
  const [countriesToSelect, setCountriesToSelect] = useState<Country[]>();

  // Fetch Countries
  useEffect(() => {
    const fetchAllStoreCountryCodes = async () => {
      try {
        const response = await agent.CountryManagement.list();

        // Sort countries on name before setting state
        const sortedResponse = response.data.sort((a: Country, b: Country) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        setCountriesToSelect(sortedResponse);
        setCountriesToSelectLoaded(true);
      } catch (error) {
        setCountriesToSelectLoaded(false);
        toast.error(`${error}`);
      }
    };
    !countriesToSelectLoaded && fetchAllStoreCountryCodes();
  }, [countriesToSelectLoaded]);

  // UseForm
  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm<StoreDeliveryAddress>({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Load form with product detail
  useEffect(() => {
    address && reset(address);
    if ((address && !address.activation_date) || !address)
      setValue("activation_date", format(Date.now(), "yyyy-MM-dd"));
  }, [address, reset, setValue]);

  // Submit
  async function submitForm({
    activation_date,
    current_address,
    country_code,
    postal_code,
    store_email,
    store_phone,
    address_1,
    address_2,
    address_3,
    city,
    id,
  }: StoreDeliveryAddress) {
    const request: StoreDeliveryAddress = {
      activation_date,
      current_address,
      country_code,
      postal_code,
      store_email,
      store_phone,
      address_1,
      address_2,
      address_3,
      city,
      id: address ? id : undefined,
    };
    changeAddressesHandler(request);
    closeHandler();
  }
  //#endregion

  //#region RENDER
  return countriesToSelectLoaded ? (
    <form
      className="flex flex-1 flex-col h-full space-y-6"
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="flex flex-1 flex-col h-full space-y-6">
        <div className="grid grid-cols-3 gap-6">
          {/* address_1 */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Address line 1
            </label>
            <ErrorMessage
              errors={errors}
              name="address_1"
              render={({ message }) => (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {message}
                </span>
              )}
            />
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("address_1", {
                  required: "The address 1 field is required",
                })}
              />
            </div>
          </fieldset>

          {/* address_2 */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Address line 2
            </label>
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("address_2")}
              />
            </div>
          </fieldset>

          {/* address_3 */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Address line 3
            </label>
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("address_3")}
              />
            </div>
          </fieldset>

          {/* city */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              City
            </label>
            <ErrorMessage
              errors={errors}
              name="city"
              render={({ message }) => (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {message}
                </span>
              )}
            />
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("city", {
                  required: "The city field is required",
                })}
              />
            </div>
          </fieldset>

          {/* postal_code */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Postal code
            </label>
            <ErrorMessage
              errors={errors}
              name="zip_code"
              render={({ message }) => (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {message}
                </span>
              )}
            />
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("postal_code", {
                  required: "The postal code field is required",
                })}
              />
            </div>
          </fieldset>

          {/* activation_date */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Activation date
            </label>
            <ErrorMessage
              errors={errors}
              name="activation_date"
              render={({ message }) => (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {message}
                </span>
              )}
            />
            <div className="mt-2">
              <input
                type="date"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("activation_date")}
              />
            </div>
          </fieldset>

          {/* country_code */}
          {countriesToSelect && (
            <fieldset>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Country
              </label>
              <ErrorMessage
                errors={errors}
                name="country_code"
                render={({ message }) => (
                  <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                    {message}
                  </span>
                )}
              />
              <div className="mt-2">
                <select
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                  {...register("country_code", {
                    required: "The country field is required",
                  })}
                >
                  {countriesToSelect.map((country: Country) => (
                    <option key={country.id} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </fieldset>
          )}

          {/* store_email */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Store email
            </label>
            <ErrorMessage
              errors={errors}
              name="store_email"
              render={({ message }) => (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {message}
                </span>
              )}
            />
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("store_email")}
              />
            </div>
          </fieldset>

          {/* store_phone */}
          <fieldset>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Store phone
            </label>
            <ErrorMessage
              errors={errors}
              name="store_phone"
              render={({ message }) => (
                <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                  {message}
                </span>
              )}
            />
            <div className="mt-2">
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                {...register("store_phone")}
              />
            </div>
          </fieldset>
        </div>
      </div>

      {/* current_address */}
      <fieldset>
        <div className="mt-2 space-y-6">
          <div className="relative flex gap-x-3">
            <div className="flex h-6 items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
                {...register("current_address")}
              />
            </div>
            <div className="text-sm leading-6">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Current address
              </label>
              <p className="text-gray-500">
                Check this option to override activation date.
              </p>
            </div>
          </div>
        </div>
      </fieldset>

      <MainSaveButtons isSubmitting={isSubmitting} isValid={isValid} />
    </form>
  ) : null;
  //#endregion
}
